/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: Montserrat-Regular;
    src: url(/assets/fonts/Cera-Pro-Black.otf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(/assets/fonts/Cera-Pro-Medium.otf);
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(/assets/fonts/Cera-Pro-Bold.otf);
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/assets/fonts/Cera-Pro-Medium.otf);
}

@font-face {
    font-family: Cera-Pro-Light;
    src: url(/assets/fonts/Cera-Pro-Light.otf);
}

html.ios,
html,
ion-app,
span {
    font-family: Montserrat-Regular !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
    font-family: Montserrat-SemiBold !important;
}

ion-content {
    h2 {
        font-size: 26px;
        font-weight: 600 !important;
    }
}

ion-button {
    --background: var(--ion-color-primary);
    --background-activated: var(--ion-color-primary);
    --background-focused: var(--ion-color-primary);
    --background-hover: var(--ion-color-primary);
    min-height: 64px;
    font-size: 18px;
    --border-radius: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    font-family: Montserrat-SemiBold !important;
    // box-shadow: 1px 2px 4px rgb(0 0 0 / 45%);
    border-radius: 6px;
}

.errp {
    color: red;
    margin-bottom: 0;

    ion-icon {
        margin-right: 6px;
        font-size: 20px;
        position: relative;
        top: 4px;
    }
}

.w-button {
    --background: var(--ion-color-light);
    --background-activated: var(--ion-color-light);
    --background-focused: var(--ion-color-light);
    --background-hover: var(--ion-color-light);
    color: var(--ion-color-primary);
    min-height: 64px;
    font-size: 18px;
    --border-radius: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    font-family: Montserrat-SemiBold !important;
    box-shadow: none !important;
}

.green-btn {
    --background: var(--ion-color-success-tint);
    --background-activated: var(--ion-color-success-tint);
    --background-focused: var(--ion-color-light);
    --background-hover: var(--ion-color-light);
    // color: #fff;
    min-height: 64px;
    font-size: 18px;
    --border-radius: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    font-family: Montserrat-SemiBold !important;
    box-shadow: none !important;
    --color-hover: var(--ion-color-primary)
}

.outline_btn {
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    color: var(--ion-color-primary);
    border: 2px solid var(--ion-color-primary);
    min-height: 64px;
    font-size: 18px;
    --border-radius: 6px;
    border-radius: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 24px !important;
    font-family: Montserrat-SemiBold !important;
    box-shadow: none !important;
}

ion-header {
    ion-grid {
        background: #fff;
    }
}

.custom {
    --background: rgb(0 0 0 / 80%);

    ion-content {
        --background: transparent;

        ion-grid {
            height: 100%;

            ion-row {
                height: 100%;
                display: table;

                width: 100%;

                ion-col {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            h3 {
                font-size: 22px;
                color: var(--ion-color-primary-contrast);
            }

            p {
                color: #cccccc;
                font-size: 16px;
                font-family: Cera-Pro-Light !important;
            }
        }
    }
}

app-home {
    ion-searchbar {
        ion-icon {
            color: var(--ion-color-primary) !important;
            border-right: 2px solid #9ab1bb;
            padding-right: 11px;
        }

        input {
            padding-inline-start: 54px !important;
        }
    }
}

ion-tab-bar {
    display: none;
}

app-faq {
    ion-content {
        ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
            transform: rotate(270deg);
        }

        ion-accordion.accordion-expanding>[slot=header] .ion-accordion-toggle-icon,
        ion-accordion.accordion-expanded>[slot=header] .ion-accordion-toggle-icon {
            transform: rotate(360deg) !important;
        }
    }
}

.loading_spinner {
    text-align: center;
}

.lession_text img {
    width: 100%;
    height: auto;
    // margin-left: 20%;
}

#made-in-ny {
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.toast_class {
    --background: #26a9e0;
    color: white;
}

.zoom {
    display: inline-block;
    position: relative;
}

/* magnifying glass icon */
.zoom::after {
    content: '';
    display: block;
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    right: 0;
}

.zoom img {
    display: block;
}

.zoom img::selection {
    background-color: transparent;
}

ion-content .nerve-toggle ion-text {
    color: black !important;
    margin-right: 11px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.zooming {
    overflow: hidden;
    user-select: none;
    touch-action: none;
}

.lession_text span {
    font-family: 'Montserrat-SemiBold' !important;
}

ion-accordion.accordion-expanded ion-item[slot="header"] {
    --background: #26a9e0 !important;
    box-shadow: 1px 3px 6px rgb(0 0 0 / 10%);
    margin-bottom: 18px;
    --min-height: 56px;
    border-radius: 6px;
    --border-radius: 6px;
    --color: white !important;

    ion-label {
        color: white !important;
    }
}

.highlight {
    background-color: #26a9e0;
    color: #fff;
    font-weight: bold;
    font-family: var(--ion-font-family, inherit) !important;
    padding: 5px;
    border-radius: 10px;
}

.panzoom {
    width: 100% !important;
    height: 100% !important;
}

.alert-radio-labe {
    white-space: break-spaces !important;
}

.zoomifyc-shadow {
    background: rgb(6 6 6 / 80%) !important;
}

.iv-close {
    top: 40px !important;
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {

    app-home ion-title,
    app-header ion-title,
    app-videos ion-title,
    app-partners ion-title,
    app-disclaimer ion-title,
    app-tac ion-title,
    app-terms-policy ion-title {
        img[src="assets/title.png"] {
            padding: 10px !important;
            width: 50% !important;
        }
    }

    .menu-logo {
        padding: 10px !important;
        width: 50% !important;
    }
}

app-terms-policy figure {
    margin: 0;
}

app-terms-policy table tr,
app-terms-policy table tr td {
    border: 1px solid;
    padding: 10px;
}

.info_toast_class {
    --background: #26a9e0;
    color: white;
    position: relative !important;
}

.error_msg {
    --background: var(--ion-color-danger);
    color: white;
}

.ng-progress-bar {
    height: 50px !important;
}

ion-progress-bar::part(track) {
    border-radius: 100px;
}

// .view-sonoanatomy{
//     --padding-start:0px;
// }  
ion-textarea textarea {
    overflow: auto !important;
}

.wp-block-table table,
tr,
td {
    border: solid;
}

.wp-block-table td {
    padding: 10px;
}

.wp-block-table {
    margin: 0 !important;
}

@media screen and (min-width:992px) {
    ion-header {
        ion-toolbar {
            ion-title {
                img {
                    width: 30% !important;
                    padding: 10px !important;
                }
            }
        }
    }

    .lession_text img {
        max-width: 60%;
    }
}


// PWA style start
@media screen and (min-width:768px) {
    .logo {
        width: 100%;
        max-width: 400px;
        margin: auto;
    }

    .pwa-layout-row {
        display: block;
        width: 100%;

        margin: auto;
        clear: both;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .pwa-container {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .vmodal {
        --width: 60%;
        --height: 70%;
    }
}


@media(min-width:540px) and (max-width: 820px) {
    .pwa-logo {
        max-width: 400px !important;
        padding-top: 10px !important;
    }

    ion-header {
        ion-toolbar {
            padding: 8px 14px !important;
        }
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/left-4.png"] {
        width: 100% !important;
        height: 400px !important;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/right-6.png"] {
        height: 400px !important;
        width: 100% !important;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/fujifilm-sonosite.jpg"] {
        height: 200px;
    }
}

@media(max-width:540px) {
    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/left-4.png"] {
        width: 100% !important;
        height: auto;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/fujifilm-sonosite.jpg"] {
        height: 200px;
    }

    app-home ion-title {
        padding: 0px !important;
    }
}

app-about-us {
    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/left-4.png"] {
        width: 75%;

        max-height: 254px;
        max-width: 100%;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/right-6.png"] {
        max-height: 254px;
        width: 23.5%;
        float: right;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/World-Synergy-logo-72-8.png"] {
        // width: 50%;
        display: inline-block;
        position: relative;
        top: -10%;
        margin-right: 30px;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/mbamedical.png"] {
        display: inline-block;
        position: relative;
        top: -6%;
        // margin-right: 20px;
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/fujifilm-sonosite.jpg"] {
        height: 210px;
        width: 25%;
    }
}

app-about-us {
    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/left-4.png"] {
        @media screen and (max-width: 600px) {
            width: 100% !important;
            height: auto;
        }
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/right-6.png"] {

        // height: 400px;
        // width: 390px;
        @media screen and (max-width: 600px) {
            width: 100% !important;
            height: auto !important;
            float: none !important;
            max-height: none !important;
        }
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/World-Synergy-logo-72-8.png"] {

        // width: 90%;
        // margin-left: 25px;
        @media screen and (max-width:600px) {
            all: unset;
            width: 90%;
            margin-left: 25px;
        }
    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/mbamedical.png"] {
        @media screen and (max-width: 600px) {
            all: unset;
            width: 100%;
            margin-top: 13px;
        }


    }

    img[src="https://myblockbuddy.com/wp-content/uploads/2023/04/fujifilm-sonosite.jpg"] {
        height: 400px;
        width: 30%;

        @media screen and (max-width: 1200px) and (min-width: 768px) {
            width: 60%;
        }

        @media screen and (max-width: 600px) {
            width: 100% !important;
            margin-top: -4%;
            height: 250px !important;
        }
    }
}





@media screen and (min-width: 992px) {
    ion-header {

        ion-toolbar {
            --padding-top: 25px !important;
            --padding-bottom: 25px !important
        }
    }

    ion-grid {
        max-width: 1180px;
        margin: auto;

    }
}

.splash_screen_mobile img {
    all: unset !important;
    position: absolute !important;
    z-index: 999999 !important;
    width: 80% !important;
    height: 100% !important;
    margin-left: 30px !important;
}

.splash_screen_pad img {
    all: unset !important;
    position: absolute !important;
    z-index: 999999 !important;
    height: 100% !important;
    width: 100% !important;
}

.splash_screen_browser {
    text-align: center !important;
}

.splash_screen_browser img {
    all: unset !important;
    /*position: absolute !important;*/
    z-index: 999999 !important;
    height: 100% !important;
}

.iv-close-custom {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-primary);
    border-radius: 4px;
    border: 1px solid white;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9;
}

.no-bullet {
    list-style-type: none;
}

.custom_padding {
    margin-bottom: 5px;
    display: block;
}

app-about-us,
app-tac,
app-privacypolicy,
app-disclaimer {
    ion-content ion-text h2:first-of-type {
        display: none;
    }
}

app-tac .disclaimer-para h2 {
    display: none;
}